import $ from 'jquery';

//contact form - button send
$('#btnSendMsg').click(function() {
    let name = $('#inputName').val();
    let eMail = $('#inputEmail').val();
    let msg = $('#inputText').val();
    //debug
    console.log("Button clicked: Name [" + name + "] - Email [" + eMail + "] - Msg [" + msg + "]");

    //send email
    /*Email.send({
        SecureToken : "563b0ae9-bd1c-44b3-b131-5935cb1207fa",
        To : 'info@sken-art.de',
        From : eMail,
        Subject : "[Kontaktformular] Neue Nachricht von '" + name + "'",
        Body : msg
    }).then(
      message => console.log(message)
    );*/
    const message = "OK";

    //check for success
    if(message == "OK") {
        $('#artContactRight').hide();
        $('#artContactRightEmailSend').show();
    }

});

//form validation
//name
$('#inputName').focusout(function() {
    let name = $('#inputName').val();
    if(name.length < "3" || name.length > "100") {
        $('#inputName').css("border", "1px solid red").css("borderTop", "4px solid red");
        swal("Fehler", "Bitte prüfe deinen Namen! (min. 3 Zeichen, max. 100 Zeichen)", "error");
    }
    else {
        $('#inputName').css("border", "1px solid green").css("borderTop", "4px solid green");
    }
});

//email
$('#inputEmail').focusout(function() {
    let email = $('#inputEmail').val();
    const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    let isValid = pattern.test(email);
    if(isValid) {
        $('#inputEmail').css("border", "1px solid green").css("borderTop", "4px solid green");
    }
    else {
        $('#inputEmail').css("border", "1px solid red").css("borderTop", "4px solid red");
        swal("Fehler", "Bitte prüfe die Eingabe der Email-Adresse!", "error");
    }
});

//text
$('#inputText').focusout(function() {
    let text = $('#inputText').val();
    if(text.length < "100" || text.length > "1000") {
        $('#inputText').css("border", "1px solid red").css("borderTop", "4px solid red");
        swal("Fehler", "Bitte die Nachticht prüfen! (min. 100 Zeichen, max. 1000 Zeichen)", "error");
    }
    else {
        $('#inputText').css("border", "1px solid green").css("borderTop", "4px solid green");
    }
});

//character count
$('#inputText').keyup(function() {
    let textLen = $('#inputText').val().length;
    console.log(textLen);
    $('#textAreaCount').html("(<span>" + textLen + "</span>/1000)");
    if(textLen < 100 || textLen > 1000) {
        $('span').css("color", "red");
    }
    else {
        $('span').css("color", "green");
    }
    
});